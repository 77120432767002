import React from "react"
import loadable from "@loadable/component"

const SliderGalleryBlock = loadable(() =>
  import("./GalleryBlock/SliderGalleryBlock")
)
const ThumbnailGalleryBlock = loadable(() =>
  import("./GalleryBlock/ThumbnailGalleryBlock")
)

const GalleryBlock = ({ block, forceLoadImages = false }) => {
  if (block.view === "slider") {
    return (
      <SliderGalleryBlock block={block} forceLoadImages={forceLoadImages} />
    )
  } else {
    return (
      <ThumbnailGalleryBlock block={block} forceLoadImages={forceLoadImages} />
    )
  }
}
export default GalleryBlock
